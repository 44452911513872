<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>医患连线</el-breadcrumb-item>
      <el-breadcrumb-item>所有连线</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <el-button type="warning" style="float:left;margin-right:10px;" size="small" @click="cjlx()">创建连线</el-button>
      <el-input v-model="doc_id" placeholder="请输入医生ID" class="marginright20" style="width:130px;float:left;" clearable size="small"></el-input>
      <el-input v-model="cust_id" placeholder="请输入用户编号" class="marginright20 width150" clearable style="float:left;width:150px;" size="small"></el-input>
      <el-select v-model="select2" size="small" placeholder="请选择连线状态" class="marginright20 width150" style="float:left;">
        <el-option v-for="item in options2" :key="item.id" :label="item.value" :value="item.id">
        </el-option>
      </el-select>
      <el-date-picker v-model="linedate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" clearable @clear="cleardate()" class="marginright20" size="small" style="width:280px;float:left;">
      </el-date-picker>
      <el-button type="primary" style="float:left;" size="small" @click="query(1)">查询</el-button>
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column prop="id" label="ID" width="50"></el-table-column>
      <el-table-column prop="channel_name" label="频道名" width="130"></el-table-column>
      <el-table-column label="用户ID" prop="cust_id" width="130"></el-table-column>
      <el-table-column label="医生姓名" prop="doc_name" width="100"></el-table-column>
      <el-table-column label="医生ID" prop="doc_id" width="70"></el-table-column>
      <!-- <el-table-column label="医生电话" prop="doc_tel" width="130"></el-table-column> -->
      <el-table-column label="预约时间" prop="date" width="160"></el-table-column>
      <el-table-column label="创建时间" prop="create_time" width="180"></el-table-column>
      <el-table-column label="描述" prop="des" width="200"></el-table-column>
      <el-table-column label="状态" prop="state" width="80">
        <template slot-scope="scope">
          <div class="opt doc">
            <span v-if="scope.row.state == 0">创建</span>
            <span v-if="scope.row.state == 5">结束</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="200">
        <template slot-scope="scope">
          <div class="opt doc">
            <p>
              <el-button size="mini" type="success" v-if="scope.row.state == 0" @click="sendmsg(scope.row)">重新发送</el-button>
              <el-button size="mini" type="primary" v-if="scope.row.state == 0" @click="look(scope.row)">查看</el-button>
            </p>
            <p>
              <el-button size="mini" type="primary" v-if="scope.row.state == 0" @click="edit(scope.row)">编辑</el-button>
              <el-button size="mini" type="warning" v-if="scope.row.state == 0" @click="changeState(scope.row)">修改状态</el-button>
            </p>
            <p>
              <el-button size="mini" type="success" v-if="
                !(
                  scope.row.fileList == '' ||
                  scope.row.fileList == null ||
                  scope.row.fileList == undefined
                )
              " @click="lookreplay(scope.row)">查看回放
              </el-button>
            </p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current" :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount" :hide-on-single-page="value"></el-pagination>
    </p>
    <!-- 修改状态 -->
    <el-dialog title="修改状态" :visible.sync="ChangeState" width="30%" @close="
        ChangeState = false;
        state = '';
      ">
      <el-form>
        <el-form-item label="">
          <el-select v-model="state" placeholder="请选择状态" size="small">
            <el-option v-for="item in stateoptions" :key="item.id" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="updateState" size="small">确定</el-button>
          <el-button @click="
              ChangeState = false;
              state = '';
            " size="small">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 修改医生 -->
    <el-dialog title="修改医生" :visible.sync="ChangeDoc" width="30%">
      <el-input placeholder="请输入医生名" clearable prefix-icon="el-icon-search" v-model="searchdoc" style="width: 160px;" size="small">
      </el-input>
      <el-button type="primary" style="margin-left:20px" size="small" @click="querydoc">查询</el-button>
      <el-radio-group style="width:100%;" v-model="binddoccheckbox">
        <el-radio style="width:100%;height: 50px;line-height: 50px;" v-for="(item, index) in binddoclist" :label="item" :key="index">{{ item.name }}</el-radio>
      </el-radio-group>
      <div style="margin-top: 20px;">
        <el-button type="primary" @click="updateDoc" size="small">确定</el-button>
        <el-button @click="ChangeDoc=false;" size="small">取消</el-button>
      </div>
    </el-dialog>
    <backaddvideo :addtc.sync="addtc"></backaddvideo>
  </div>
</template>
<script>
import backaddvideo from "../components/backaddvideo.vue";
export default {
  components: {
    backaddvideo,
  },
  data() {
    return {
      sid: "",
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      stateoptions: [
        { id: 0, label: "创建" },
        { id: 5, label: "结束" },
      ],
      ChangeState: false,
      state: "",
      row: "",
      client: "",
      linedate: "",
      doc_id: "",
      cust_id: "",
      ChangeDoc: false,
      binddoccheckbox: "",
      binddoclist: [],
      searchdoc: "",
      docrow: "",
      videourl: "",
      select2: "",
      options2: [
        { id: 0, value: "创建" },
        { id: 5, value: "结束" },
      ],
      addtc: false,
    };
  },
  mounted() {
    this.sid = sessionStorage.getItem("servicer_id");
  },
  methods: {
    query(page) {
      let docstr, custstr, statestr, datestr;
      if (this.doc_id != "") {
        docstr = "&doc_id=" + this.doc_id;
      } else {
        docstr = "";
      }
      if (this.cust_id != "") {
        custstr = "&cust_id=" + this.cust_id;
      } else {
        custstr = "";
      }
      if (this.select2 !== "") {
        statestr = "&state=" + this.select2;
      } else {
        statestr = "";
      }
      if (this.linedate) {
        datestr =
          "&datetime1=" + this.linedate[0] + "&datetime2=" + this.linedate[1];
      } else {
        datestr = "";
      }
      this.axios
        .get(
          "/channel/get_gu_channel?page=" +
            page +
            "&size=" +
            this.PageSize +
            docstr +
            custstr +
            statestr +
            datestr
        )
        .then((res) => {
          this.list = [];
          this.totalCount = res.data.count;
          this.list = res.data.result;
        });
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.query(currentPage);
    },
    //查看
    look(row) {
      this.row = row;
      let routeData = this.$router.resolve({
        path: "/backvideo/lookvideodetail",
        query: { id: row.id },
      });
      window.open(routeData.href, "_blank");
    },
    //修改状态
    changeState(row) {
      this.ChangeState = true;
      this.row = row;
    },
    updateState() {
      console.log(this.state);
      this.axios
        .post(
          "/channel/update_gu_channel_state",
          this.qs.stringify({ id: this.row.id, state: this.state })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.ChangeState = false;
            // this.reload();
            this.query(1);
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    edit(row) {
      this.ChangeDoc = true;
      this.binddoclist = [];
      this.docrow = row;
    },
    querydoc() {
      //搜索医生姓名
      if (this.searchdoc == "") {
        this.$message({
          type: "error",
          message: "请填写医生",
        });
        return;
      }
      this.axios
        .post(
          "/gu/search_gu_docs",
          this.qs.stringify({ keyword: this.searchdoc, is_flag: "all" })
        )
        .then((rs) => {
          if (rs.data.code == 0) {
            this.binddoclist = rs.data.result;
          } else {
            this.$message({
              type: "error",
              message: "没有搜索到该医生",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateDoc() {
      let form = {
        channel_name: this.docrow.channel_name,
        channel_token: this.docrow.channel_token,
        input_id: this.docrow.input_id,
        cust_id: this.docrow.cust_id,
        doc_id: this.binddoccheckbox.id,
        id: this.docrow.id,
      };
      console.log(form);
      this.axios
        .post("/channel/update_gu_channel", this.qs.stringify(form))
        .then((res) => {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.ChangeDoc = false;
          this.query(1);
        });
    },
    cleardate() {
      this.linedate = [];
    },
    //查看回放
    lookreplay(row) {
      this.videourl = "http://cdn.51xk.info/" + row.fileList;
      let routeData = this.$router.resolve({
        path: "/backvideo/videoLookReplay",
        query: { des: row.des, url: this.videourl },
      });
      window.open(routeData.href, "_blank");
    },
    //创建连线
    cjlx() {
      this.addtc = true;
    },
    //给患者和医生发消息
    sendmsg(aa) {
      console.log(aa);
      //   console.log(this.custtel);
      //   console.log(this.doc_tel);
      var form = {
        doctorId: aa.doc_tel,
        channel: aa.channel_name,
        id: aa.id,
      };
      this.axios
        .get("/gu/get_cust_info_by_custid?cust_id=" + aa.cust_id)
        .then((res) => {
          if (res.data.code == 0) {
            var tel = res.data.result.Tel1;
            form.userId = tel;
            console.log(form);
            this.axios
              .post(
                this.serveurl.serve + "/api/Doctor/start_video_contact",
                this.qs.stringify(form)
              )
              .then((res) => {
                if (res.data.code == 1) {
                  this.$message({
                    type: "success",
                    message: res.data.msg + "给医生和患者",
                  });
                } else {
                  this.$message({
                    type: "error",
                    message: res.data.msg,
                  });
                }
              });
          } else {
            this.$message({
              type: "error",
              message: res.data.msg,
            });
          }
        });
    },
  },
};
</script>
<style>
.add {
  width: 100%;
  height: 50px;
  text-align: left;
  float: left;
}
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}
.opt p{
  margin: 0 0 8px 0;
}
.marginright20 {
  margin-right: 10px;
}
.width150 {
  width: 150px;
}
</style>
