<template>
  <div>
    <el-dialog title="创建连线" width="50%" :visible.sync="show" @close="exit()">
      <el-form label-width="120px" style="text-align: left;">
        <el-form-item label="用户编号">
          <el-input v-model="custid" size="small" placeholder="请输入用户编号"></el-input>
        </el-form-item>
        <el-form-item label="医生" style="margin-bottom: 8px;">
          <div>
            <el-input
              placeholder="请输入医生名"
              clearable
              prefix-icon="el-icon-search"
              v-model="searchdoc"
              style="width: 30%;"
              @clear="cleardoc"
               size="small"
            >
            </el-input>
            <el-button type="primary" style="margin-left:20px" @click="querydoc" size="small">查询</el-button>
          </div>
          <div>
            <el-radio-group v-model="binddoccheckbox" @change="selectdoc" size="small">
              <el-radio
                style="width:70px;height: 50px;line-height: 50px;"
                v-for="(item, index) in binddoclist"
                :label="item"
                :key="index"
                >{{ item.name }}
              </el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-date-picker
            v-model="date"
            type="datetime"
            placeholder="选择日期时间"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
             size="small"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="描述">
          <el-input type="textarea" size="small" placeholder="请添加描述" :rows="5" resize="none" v-model="des"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exit()" size="small">取 消</el-button>
        <el-button type="primary" @click="VideoUpdate" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["addtc"],
  data() {
    return {
      show: false,
      custid: "",
      docid: "",
      searchdoc: "",
      binddoccheckbox: "",
      date: "",
      des: "",
      binddoclist: [],
      form: {},
      servicer_id: "9999",
      custtel: "",
    };
  },
  watch: {
    addtc(o, n) {
      this.show = o;
    },
  },
  mounted() {},
  methods: {
    exit() {
      this.show = false;
      this.searchdoc = "";
      this.binddoclist = "";
      this.date = "";
      this.des = "";
      this.$emit("update:addtc", false);
    },
    cleardoc() {
      this.binddoclist = [];
      this.binddoccheckbox = "";
    },
    querydoc() {
      //搜索医生姓名
      if (this.searchdoc == "") {
        this.$message({
          type: "error",
          message: "请填写医生",
        });
        return;
      }
      this.axios
        .post("/gu/search_gu_docs", this.qs.stringify({ keyword: this.searchdoc, is_flag: "all" }))
        .then((rs) => {
          if (rs.data.code == 0) {
            this.binddoclist = rs.data.result;
          } else {
            this.$message({
              type: "error",
              message: "没有搜索到该医生",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectdoc() {
      this.doc_tel = this.binddoccheckbox.tel;
    },
    VideoUpdate() {
      if (this.custid == "") {
        this.$message({
          type: "error",
          message: "请填写用户编号",
        });
        return;
      }
      if (this.binddoccheckbox == "") {
        this.$message({
          type: "error",
          message: "请选择医生",
        });
        return;
      }
      this.docid = this.binddoccheckbox.id;
      //通过用户编号查询患者手机号
      this.axios.get("/gu/get_cust_info_by_custid?cust_id=" + this.custid).then((res) => {
        if (res.data.code == 0) {
          var tel = res.data.result.Tel1;
          //查询患者的手机号是否再聊天系统中注册
          this.axios
            .get(this.serveurl.serve + "/api/User/user_belong_service?userID=" + tel)
            .then((res) => {
              if (res.data.code == 1) {
                if (res.data.result == "") {
                  this.$message({
                    type: "error",
                    message: "该用户未在聊天系统注册，无法发送消息",
                  });
                } else {
                  this.custtel = tel;
                  this.insertvideo();
                }
              } else {
              }
            })
            .catch((res) => {
              console.log(res);
            });
        } else {
          this.$message({
            type: "error",
            message: res.data.msg,
          });
        }
      });
    },
    //创建视频
    async insertvideo() {
      const time = new Date().getTime();
      //获取视频token
      let token = await this.axios
        .get(
          "http://192.168.11.253:8080/agora/sample/RtcTokenBuilderSample.php?channel=" +
            time +
            "&uid=" +
            this.sid
        )
        .then((res) => {
          return res.data.token;
        })
        .catch((res) => {
          console.log(res);
        });

      this.form = {
        channel_name: time,
        channel_token: token,
        input_id: this.servicer_id,
        cust_id: this.custid,
        doc_id: this.binddoccheckbox.id,
        date: this.date,
        des: this.des,
      };
    //   console.log(this.form);
      this.axios
        .post("/channel/insert_gu_channel", this.qs.stringify(this.form))
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "创建成功",
            });
            this.form.id = res.data.result;
            this.binddoccheckbox = "";
            this.binddoclist = [];
            this.searchdoc = "";
            this.custid = "";
            this.date = "";
            this.des = "";
            this.exit();
            console.log(this.form);
            //给患者和医生发消息
            this.sendmsg(this.form);
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    //给患者和医生发消息
    sendmsg(aa) {
        console.log(aa)
      var form = {
        userId: this.custtel,
        doctorId: this.doc_tel,
        channel: aa.channel_name,
        id: aa.id,
      };
      console.log(form);
      this.axios
        .post(this.serveurl.serve + "/api/Doctor/start_video_contact", this.qs.stringify(form))
        .then((res) => {
          if (res.data.code == 1) {
            this.$message({
              type: "success",
              message: res.data.msg+"给医生和患者",
            });
          } else {
            this.$message({
              type: "error",
              message: res.data.msg,
            });
          }
        });
    },
  },
};
</script>
